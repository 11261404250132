// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uSNlw{position:fixed;top:63px;right:0;width:100%;height:calc(100% - 63px);pointer-events:none}.VERu8{pointer-events:all}.au_vC{min-width:440px;height:100%;background-color:var(--wpp-grey-color-000);border-radius:var(--wpp-border-radius-l);box-shadow:0 8px 32px 0 rgba(52,58,63,.25)}.THCES{padding:16px 12px 12px;-webkit-user-select:none;user-select:none}", "",{"version":3,"sources":["webpack://./src/components/overlay/OverlayManager.module.scss"],"names":[],"mappings":"AAAA,OACE,cAAA,CACA,QAAA,CACA,OAAA,CACA,UAAA,CACA,wBAAA,CACA,mBAAA,CAGF,OACE,kBAAA,CAGF,OACE,eAAA,CACA,WAAA,CACA,0CAAA,CACA,wCAAA,CACA,0CAAA,CAGF,OACE,sBAAA,CACA,wBAAA,CAAA,gBAAA","sourcesContent":[".dragSurface {\n  position: fixed;\n  top: 63px;\n  right: 0;\n  width: 100%;\n  height: calc(100% - 63px);\n  pointer-events: none;\n}\n\n.overlayRnd {\n  pointer-events: all;\n}\n\n.overlay {\n  min-width: 440px;\n  height: 100%;\n  background-color: var(--wpp-grey-color-000);\n  border-radius: var(--wpp-border-radius-l);\n  box-shadow: 0 8px 32px 0 rgb(52 58 63 / 25%);\n}\n\n.header {\n  padding: 16px 12px 12px;\n  user-select: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dragSurface": "uSNlw",
	"overlayRnd": "VERu8",
	"overlay": "au_vC",
	"header": "THCES"
};
export default ___CSS_LOADER_EXPORT___;
