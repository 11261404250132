import { WppIconFile, WppTypography, WppIconDownload } from '@platform-ui-kit/components-library-react'

import styles from 'components/assistant/chat/chatBubble/brainAnswer/brainItem/BrainItem.module.scss'
import { Flex } from 'components/common/flex/Flex'

interface Props {
  title: string
  description: string
  url: string
}

export const BrainItem = ({ title, description, url }: Props) => {
  return (
    <Flex gap={12} className={styles.card}>
      <WppIconFile height={98} width={98} />
      <div className={styles.mainContent}>
        <WppTypography className={styles.name} type="2xs-strong">
          {title}
        </WppTypography>
        <div>
          <WppTypography className={styles.description} type="s-body">
            {description}
          </WppTypography>
        </div>
        <Flex justify="end" align="center">
          <a key={title || 'Document'} href={url} target="_blank" rel="noreferrer" className={styles.downloadLink}>
            <WppIconDownload height={20} width={20} className={styles.downloadIcon} />
          </a>
        </Flex>
      </div>
    </Flex>
  )
}
