import clsx from 'clsx'
import React, { useState } from 'react'

import AppsList from 'components/assistant/apps/AppsList'
import styles from 'components/assistant/chat/chatSidebar/Sidebar.module.scss'

interface Props {
  isAiAssistantOpen?: boolean
  assistantPosition?: { x: number; y: number }
  assistantWidth?: number
}

export const Sidebar = (props: Props) => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState<boolean>(false)

  const handleMouseEnter = () => {
    setIsSidebarExpanded(true)
  }

  return (
    <div className={styles.container}>
      <div
        className={clsx(styles.wrapper, isSidebarExpanded ? styles.expanded : styles.collapsed)}
        onMouseLeave={() => setIsSidebarExpanded(false)}
      >
        <AppsList onMouseEnter={handleMouseEnter} isSidebarExpanded={isSidebarExpanded} {...props} />
      </div>
    </div>
  )
}

export default Sidebar
