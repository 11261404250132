import { WppDivider } from '@platform-ui-kit/components-library-react'
import { useState, useMemo, useEffect } from 'react'
import { useImmer } from 'use-immer'

import styles from 'components/assistant/assistantPopover/assistantContent/AssistantContent.module.scss'
import { Chat } from 'components/assistant/chat/Chat'
import { SavedConversationsList } from 'components/assistant/savedConversationsList/SavedConversationsList'
import { AssistantTabs } from 'constants/ui'
import { ChatProvider } from 'contexts/ChatContext'
import { ConversationDto } from 'types/dto/ConversationDto'
import { UserSettingsDto } from 'types/dto/UserSettingsDto'

import Sidebar from '../../chat/chatSidebar/Sidebar'
import { AssistantUserSettings } from '../assistantUserSettings/AssistantUserSettings'

interface Props {
  selectedTabOuter?: AssistantTabs
  onSelectTabOuter: (tab: AssistantTabs) => void
  onSelectConversation: (conversation: ConversationDto | undefined) => void
  closeHistoryConversation?: boolean
  clearConversation?: boolean
  onUpdateUserSettings: (userSettings: UserSettingsDto) => void
  assistantWidth?: number
  isAiAssistantOpen?: boolean
  assistantPosition?: { x: number; y: number }
}

export const AssistantContent = ({
  selectedTabOuter = AssistantTabs.CHAT,
  onSelectTabOuter,
  onSelectConversation,
  closeHistoryConversation,
  clearConversation,
  onUpdateUserSettings,
  assistantWidth = 440,
  isAiAssistantOpen,
  assistantPosition,
}: Props) => {
  const [searchString, setSearchString] = useState<string | undefined>(undefined)
  const [selectedConversation, updateSelectedConversation] = useImmer<ConversationDto | undefined>(undefined)
  const [conversation, updateConversation] = useImmer<ConversationDto | undefined>(undefined)

  const handleSelectConversation = (conversation: ConversationDto | undefined, searchString: string | undefined) => {
    updateSelectedConversation(conversation)
    setSearchString(searchString)

    if (conversation) {
      onSelectConversation(conversation)
    } else {
      onSelectConversation(undefined)
    }
  }

  useEffect(() => {
    if (closeHistoryConversation) {
      updateSelectedConversation(undefined)
    }
  }, [closeHistoryConversation, updateSelectedConversation])

  useEffect(() => {
    if (clearConversation) {
      updateConversation(undefined)
    }
  }, [clearConversation, updateConversation])

  const saveToggleValue = useMemo(() => true, [])

  const handleDeleteConversation = (conversationParam: ConversationDto) => {
    if (conversation?.id === conversationParam?.id) {
      updateConversation(undefined)
    }
  }

  const handleClearConversation = () => {
    updateConversation(undefined)
  }

  const handleClearSelectedConversation = ({
    startNewConversation,
  }: {
    conversationId: string
    startNewConversation?: boolean
  }) => {
    updateSelectedConversation(undefined)
    onSelectConversation(undefined)
    setSearchString(undefined)

    if (startNewConversation) {
      onSelectTabOuter(AssistantTabs.CHAT)
    }
  }

  const chatValues = {
    tab: selectedTabOuter,
    isAiAssistantOpen,
    [AssistantTabs.CHAT]: {
      conversation,
      isConversationSaved: saveToggleValue,
      updateConversation,
      onClearConversation: handleClearConversation,
      questionInputVisible: selectedTabOuter === AssistantTabs.CHAT,
    },
    [AssistantTabs.HISTORY]: {
      conversation: selectedConversation,
      isConversationSaved: saveToggleValue,
      updateConversation: updateSelectedConversation,
      searchString,
      onClearConversation: handleClearSelectedConversation,
      questionInputVisible: !!selectedConversation,
    },
  }
  return (
    <div className={styles.container}>
      {selectedTabOuter === AssistantTabs.CHAT && (
        <>
          <Sidebar
            assistantWidth={assistantWidth}
            isAiAssistantOpen={isAiAssistantOpen}
            assistantPosition={assistantPosition}
          />
          <WppDivider className={styles.verticalDivider} />
        </>
      )}
      <div
        style={
          selectedTabOuter === AssistantTabs.CHAT ||
          (selectedTabOuter === AssistantTabs.HISTORY && selectedConversation)
            ? { display: 'block', width: '100%', height: '100%', overflow: 'hidden' }
            : { display: 'none' }
        }
      >
        <ChatProvider value={chatValues}>
          <Chat />
        </ChatProvider>
      </div>

      {selectedTabOuter === AssistantTabs.HISTORY && (
        <div
          style={
            selectedTabOuter === AssistantTabs.HISTORY && !selectedConversation
              ? { display: 'block', height: '100%', width: '100%', position: 'relative' }
              : { display: 'none' }
          }
        >
          <SavedConversationsList
            onSelectConversation={(conversation, searchString = undefined) =>
              handleSelectConversation(conversation, searchString)
            }
            onDeleteConversation={conversation => handleDeleteConversation(conversation)}
          />
        </div>
      )}

      {selectedTabOuter === AssistantTabs.SETTINGS && (
        <AssistantUserSettings onUpdateUserSettings={onUpdateUserSettings} />
      )}
    </div>
  )
}
