import { WppTypography, WppGrid } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import styles from 'components/assistant/chat/chatBubble/beachAnswer/BeachAnswer.module.scss'
import { MarkdownText } from 'components/assistant/chat/chatBubble/markdownText/MarkdownText'
import { Flex } from 'components/common/flex/Flex'
import { BeachDataContentDto } from 'types/dto/ConversationDto'

interface Props {
  content: BeachDataContentDto
}

export const BeachAnswer = ({ content }: Props) => {
  return (
    <Flex className={styles.chatBubbleAnswer} direction="column" wrap="nowrap" gap={12}>
      {content.answer.length > 0 && (
        <WppTypography tag="p" type="s-body" className={styles.text}>
          <MarkdownText text={content.answer} />
        </WppTypography>
      )}

      <Flex direction="column" wrap="nowrap" gap={12}>
        <WppGrid>
          <Flex className={styles.row} direction="row" wrap="nowrap" gap={0}>
            <div className={styles.firstCol}>
              <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                <MarkdownText text="**Audience Segment**" />
              </WppTypography>
            </div>
            <div className={styles.secondCol}>
              <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                <MarkdownText text="**Relevant Moments to Activate**" />
              </WppTypography>
            </div>
            <div className={styles.thirdCol}>
              <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                <MarkdownText text="**Weekly+ Share**" />
              </WppTypography>
            </div>
          </Flex>

          {content.segments.map((insight, index) => (
            <Flex key={index} className={styles.row} direction="row" wrap="nowrap" gap={0}>
              <div className={styles.firstCol}>
                <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                  <MarkdownText text={insight.audience_segment || insight.audienceSegment} />
                </WppTypography>
              </div>
              <div className={styles.secondCol}>
                <WppTypography tag="p" type="s-body" className={clsx(styles.text, styles.textHidden)}>
                  {insight.moments}
                </WppTypography>
              </div>
              <div className={styles.thirdCol}>
                <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                  {insight.potential}
                </WppTypography>
              </div>
            </Flex>
          ))}
        </WppGrid>
      </Flex>
    </Flex>
  )
}
