import { WppIconClose, WppActionButton, WppTypography } from '@platform-ui-kit/components-library-react'
import React, { createRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { createRoot, Root } from 'react-dom/client'
import { Rnd } from 'react-rnd'

import DelayUnmount from 'components/common/DelayUnmount'
import { Flex } from 'components/common/flex/Flex'
import { debounce } from 'utils/debounce'

import styles from './OverlayManager.module.scss'

interface OverlayOptions {
  defaultWidth?: number
  resizable?: boolean
  defaultPosition?: { x: number; y: number }
  name?: string
  icon?: React.ReactNode
}
interface OverlayProp {
  onClose: () => void
  content: React.ReactNode
  options?: OverlayOptions
}

let root: Root | null = null

const Overlay = forwardRef(({ onClose, content, options }: OverlayProp, ref) => {
  const [showModal, setShowModal] = useState(true)

  const DEFAULT_OVERLAY_SIZE = {
    width: `${window.innerWidth - 440 - 48}px`,
    height: `${window.innerHeight - 63 - 48}px`,
  }

  const DEFAULT_OVERLAY_POSITION = options?.defaultPosition || {
    x: window.innerWidth - 440 - 8 - (options?.defaultWidth || 0) - 8,
    y: 24,
  }

  const [overlayPosition, setOverlayPosition] = useState(DEFAULT_OVERLAY_POSITION)
  const [overlaySize, setOverlaySize] = useState(
    options?.defaultWidth ? { ...DEFAULT_OVERLAY_SIZE, width: `${options.defaultWidth}px` } : DEFAULT_OVERLAY_SIZE,
  )

  const _isMounted = useRef(false)

  useEffect(() => {
    _isMounted.current = true
    return () => {
      _isMounted.current = false
    }
  }, [])

  useImperativeHandle(ref, () => ({
    hideModal,
  }))

  const hideModal = () => {
    setShowModal(false)
  }

  return (
    <div>
      <DelayUnmount delayTime={200} show={showModal}>
        <div className={styles.dragSurface}>
          <Rnd
            minWidth={440}
            minHeight={600}
            bounds="parent"
            enableResizing={options?.resizable || false}
            position={{ x: overlayPosition.x, y: overlayPosition.y }}
            size={{ width: overlaySize.width, height: overlaySize.height }}
            enableUserSelectHack={false}
            onDragStop={(e, d) => {
              setOverlayPosition({ x: d.x, y: d.y })
            }}
            onResizeStop={(e, direction, ref, delta, position) => {
              setOverlaySize({
                width: ref.style.width,
                height: ref.style.height,
              })
              setOverlayPosition({ x: position.x, y: position.y })
            }}
            cancel=".cancel-drag"
            className={styles.overlayRnd}
          >
            <div className={styles.overlay}>
              <Flex className={styles.header} justify="between" align="center" gap={8}>
                <Flex align="center" gap={8}>
                  {options?.icon && React.cloneElement(options.icon as any, { color: 'var(--wpp-grey-color-800)' })}
                  <WppTypography type="m-strong">{options?.name || ''}</WppTypography>
                </Flex>
                <Flex align="center" gap={2}>
                  <WppActionButton variant="secondary" onClick={onClose} className="cancel-drag">
                    <WppIconClose slot="icon-start" color="var(--wpp-grey-color-800)" />
                  </WppActionButton>
                </Flex>
              </Flex>
              {content}
            </div>
          </Rnd>
        </div>
      </DelayUnmount>
    </div>
  )
})

export const showOverlay = (
  type: string,
  content: any,
  options?: {
    defaultWidth?: number
    resizable?: boolean
    defaultPosition?: { x: number; y: number }
    name?: string
    icon?: React.ReactNode
  },
  callback?: () => void,
) => {
  const ref = createRef()
  const container = document.getElementById('modals')

  const cl = debounce(() => {
    root?.unmount()
    root = null

    // @ts-ignore
    ref.current?.hideModal && ref.current.hideModal()
    setTimeout(() => {
      if (container) {
        container.innerHTML = ''
      }
    }, 300)
  }, 50)

  const modal = (
    <Overlay
      options={options}
      ref={ref}
      onClose={() => {
        callback && callback()
        cl()
      }}
      content={content}
    />
  )

  if (!root) {
    root = (container && createRoot(container)) || null
  }

  if (root) {
    root.render(modal)
  }

  return {
    close: () => cl(),
  }
}
