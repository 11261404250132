// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kJ2Ir{width:100%;margin-bottom:8px;padding:12px 16px;background-color:var(--wpp-grey-color-200);border-radius:var(--wpp-border-radius-m)}.QS6sO{width:100%;white-space:pre-line}.gt55_ .WTO5j{width:50%;min-height:95px;padding:12px 12px;border:1px solid #000;border-top:none;display:flex;align-items:center}.gt55_ .WTO5j:last-child{border-left:none}.gt55_:first-child .WTO5j{border:1px solid #000}.gt55_:first-child .WTO5j:last-child{border-left:none}", "",{"version":3,"sources":["webpack://./src/components/assistant/chat/chatBubble/litInsightsHardcodedAnswer/LitInsightsAnswer.module.scss"],"names":[],"mappings":"AAAA,OACE,UAAA,CACA,iBAAA,CACA,iBAAA,CACA,0CAAA,CACA,wCAAA,CAGF,OACE,UAAA,CACA,oBAAA,CAIA,cACE,SAAA,CACA,eAAA,CACA,iBAAA,CACA,qBAAA,CACA,eAAA,CAEA,YAAA,CACA,kBAAA,CAEA,yBACE,gBAAA,CAKF,0BACE,qBAAA,CAEA,qCACE,gBAAA","sourcesContent":[".chatBubbleAnswer {\n  width: 100%;\n  margin-bottom: 8px;\n  padding: 12px 16px;\n  background-color: var(--wpp-grey-color-200);\n  border-radius: var(--wpp-border-radius-m);\n}\n\n.text {\n  width: 100%;\n  white-space: pre-line;\n}\n\n.row {\n  .col {\n    width: 50%;\n    min-height: 95px;\n    padding: 12px 12px;\n    border: 1px solid black;\n    border-top: none;\n\n    display: flex;\n    align-items: center;\n\n    &:last-child {\n      border-left: none;\n    }\n  }\n\n  &:first-child {\n    .col {\n      border: 1px solid black;\n\n      &:last-child {\n        border-left: none;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatBubbleAnswer": "kJ2Ir",
	"text": "QS6sO",
	"row": "gt55_",
	"col": "WTO5j"
};
export default ___CSS_LOADER_EXPORT___;
