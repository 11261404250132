import { WppTypography, WppGrid } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import styles from 'components/assistant/chat/chatBubble/brainAnswer/BrainAnswer.module.scss'
import { MarkdownText } from 'components/assistant/chat/chatBubble/markdownText/MarkdownText'
import { Flex } from 'components/common/flex/Flex'
import { BrainContentDto } from 'types/dto/ConversationDto'

import { BrainItem } from './brainItem/BrainItem'

interface Props {
  content: BrainContentDto
}

export const BrainAnswer = ({ content }: Props) => {
  return (
    <Flex className={styles.chatBubbleAnswer} direction="column" wrap="nowrap" gap={12}>
      {content.answer.length > 0 && (
        <WppTypography tag="p" type="s-body" className={styles.text}>
          <MarkdownText text={content.answer} />
        </WppTypography>
      )}

      {content.table && (
        <Flex direction="column" wrap="nowrap" gap={12}>
          <WppGrid>
            <Flex className={styles.row} direction="row" wrap="nowrap" gap={0}>
              <div className={styles.firstCol}>
                <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                  <MarkdownText text="**Segment**" />
                </WppTypography>
              </div>
              <div className={styles.secondCol}>
                <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                  <MarkdownText text="**Tech savvy**" />
                </WppTypography>
              </div>
              <div className={styles.thirdCol}>
                <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                  <MarkdownText text="**Innovation**" />
                </WppTypography>
              </div>
            </Flex>

            {content.table.map((item, index) => (
              <Flex key={index} className={styles.row} direction="row" wrap="nowrap" gap={0}>
                <div className={styles.firstCol}>
                  <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                    <MarkdownText text={item.brain_segment} />
                  </WppTypography>
                </div>
                <div className={styles.secondCol}>
                  <WppTypography tag="p" type="s-body" className={clsx(styles.text, styles.textHidden)}>
                    {item.tech_savvy}
                  </WppTypography>
                </div>
                <div className={styles.thirdCol}>
                  <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                    {item.innovation}
                  </WppTypography>
                </div>
              </Flex>
            ))}
          </WppGrid>
        </Flex>
      )}

      {content.list_files &&
        content.list_files.map((item, subIndex) => (
          <BrainItem title={item.title} description={item.description} url={item.url} key={subIndex} />
        ))}
    </Flex>
  )
}
