import clsx from 'clsx'
import React, { cloneElement, useState } from 'react'

import styles from './Toolbar.module.scss'
import AppsList from '../apps/AppsList'

export function Toolbar({ button }: { button: React.ReactNode }) {
  const [openToolbar, setOpenToolbar] = useState(false)

  return (
    <div onMouseEnter={() => setOpenToolbar(true)} onMouseLeave={() => setOpenToolbar(false)}>
      <div className={clsx(styles.toolbarContainer, [openToolbar ? styles.opened : styles.closed])}>
        <AppsList isAiAssistantOpen={false} />
      </div>
      {cloneElement(button as React.ReactElement, {
        className: `${(button as React.ReactElement).props.className} ${
          openToolbar ? styles.toolbarMainButtonExpanded : ''
        }`.trim(),
      })}
    </div>
  )
}
