import { WppGrid, WppTypography } from '@platform-ui-kit/components-library-react'
import { useOs } from '@wpp-open/react'
import clsx from 'clsx'

import styles from 'components/assistant/chat/chatBubble/litInsightsHardcodedAnswer/LitInsightsAnswer.module.scss'
import { MarkdownText } from 'components/assistant/chat/chatBubble/markdownText/MarkdownText'
import { Flex } from 'components/common/flex/Flex'
import { LitInsightsContentDto } from 'types/dto/ConversationDto'

interface Props {
  content: LitInsightsContentDto
}

export const LitInsightsAnswer = ({ content }: Props) => {
  const { osContext } = useOs()
  const tenantUrl = osContext.tenant.homeUrl.slice(0, -1)

  const formatLinksInText = (inputString: string, baseUrl: string) => {
    const regex = /\{baseUrl:(.*?)\}/g

    const replacedString = inputString.replace(regex, (_, postfix) => {
      return `[${baseUrl}${postfix}](${baseUrl}${postfix})`
    })

    return replacedString
  }
  const textWithLinks = formatLinksInText(content.answer, tenantUrl)

  return (
    <Flex className={styles.chatBubbleAnswer} direction="row" wrap="nowrap" gap={12}>
      {content.answer.length > 0 && (
        <WppTypography tag="p" type="s-body" className={styles.text}>
          <MarkdownText text={textWithLinks} />
        </WppTypography>
      )}

      {content.insights.length > 0 && (
        <Flex className={styles.imageContainer} direction="column" wrap="nowrap" gap={12}>
          <WppGrid>
            <Flex className={styles.row} direction="row" wrap="nowrap" gap={0}>
              <div className={styles.col}>
                <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                  <MarkdownText text="**Audience Segment**" />
                </WppTypography>
              </div>
              <div className={styles.col}>
                <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                  <MarkdownText text="**Insight**" />
                </WppTypography>
              </div>
            </Flex>

            {content.insights.map((insight, index) => (
              <Flex key={index} className={styles.row} direction="row" wrap="nowrap" gap={0}>
                <div className={styles.col}>
                  <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                    <MarkdownText text={insight.audience_segment || insight.audienceSegment} />
                  </WppTypography>
                </div>
                <div className={styles.col}>
                  <WppTypography tag="p" type="s-body" className={clsx(styles.text)}>
                    {insight.insight}
                  </WppTypography>
                </div>
              </Flex>
            ))}
          </WppGrid>
        </Flex>
      )}
    </Flex>
  )
}
